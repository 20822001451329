import {useEffect, useState} from 'react';

export default function useWindowSize() {
    const isClient = typeof window === 'object';

    const getSize = () => {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
            viewPortWidth: isClient ? getViewport() : undefined,
        };
    }

    const getViewport = () => {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        if (width <= 576) return 'xs'
        if (width <= 768) return 'sm'
        if (width <= 992) return 'md'
        if (width <= 1200) return 'lg'
        return 'xl'
    }

    const [windowSize, setWindowSize] = useState(getSize());

    const handleResize = () => setWindowSize(getSize());

    useEffect(() => {
        if (!isClient) return;
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return windowSize;
}