import {flattenObjectAndReturnAsAList} from "../../core/services/utils";

/**
 * All the route names of the application
 */
const routes = {
    public: {
        landing: '/',
        warrantyAndReturnChat: '/warranty-and-return-chat/:token',
    },
    auth: {
        base: '/auth',
        login: 'auth/login/:token?/:time?',
        logout: 'auth/logout',
    },
    error: {
        base: '/error',
        notFound: '/error/notFound',
        accessDenied: '/error/accessDenied',
        serverError: '/error/serverError',
        serverErrorForSection: '/error/serverErrorForSection',
        accessDeniedForSection: '/error/accessDeniedForSection',
    },
}

export default routes;

/**
 * All the routes with variable parts in form of functions
 */
export const routeFunctions = {
    public: {
        warrantyAndReturnChat: (token) => `/warranty-and-return-chat/${token}`,
    },
}


/**
 * The Accumulated list of all routes for difference route entries
 */
export const routesLists = {
    //                                  ************* ALL ROUTES **************

    all: flattenObjectAndReturnAsAList(routes),
    allWithServerErrorSection: flattenObjectAndReturnAsAList(routes, routes.error.serverErrorForSection),
    allWithAccessDeniedSection: flattenObjectAndReturnAsAList(routes, routes.error.accessDeniedForSection),

    //                                  ************* PUBLIC ROUTES **************

    public: flattenObjectAndReturnAsAList(routes.public),
    publicWithServerErrorSection: flattenObjectAndReturnAsAList(routes.public, routes.error.serverErrorForSection),
    publicWithAccessDeniedSection: flattenObjectAndReturnAsAList(routes.public, routes.error.accessDeniedForSection),

    //                                  ************* AUTH ROUTES **************

    auth: flattenObjectAndReturnAsAList(routes.auth),
    authWithServerErrorSection: flattenObjectAndReturnAsAList(routes.auth, routes.error.serverErrorForSection),
    authWithAccessDeniedSection: flattenObjectAndReturnAsAList(routes.auth, routes.error.accessDeniedForSection),

    //                                  ************* ERROR ROUTES **************
    error: flattenObjectAndReturnAsAList(routes.error),
}
