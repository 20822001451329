import React, {forwardRef} from "react";
import {Dialog as MuiDialog, Paper, Slide} from '@material-ui/core';
import Draggable from 'react-draggable';
import {ReactComponent as CloseIcon} from "../../../../assets/images/dialog-close-icon.svg";
import classnames from "classnames";


const SlideTransitionComponent = forwardRef((
    {direction, ...props},
    ref) => {

    return (
        <Slide direction={direction} ref={ref} {...props} />
    );
});

const DraggableComponent = (props) => {
    const {onCloseClick, slide, slideDirection, extraClassName, className, loading, role, ...rest} = props;

    return (
        <>
            <Draggable handle="#draggable-dialog" cancel={'[class*="MuiDialogContent-root"]'}>
                <div className={classnames('d-flex flex-column dialog-paper-container', className)} role={role}>
                    <div className={'dialog-close-container'}>
                        <CloseIcon onClick={(e) => loading ? () => false : onCloseClick(e)} className={'close-icon'}/>
                    </div>
                    <Paper id={'draggable-dialog'} {...{
                        ...rest,
                        className: classnames(rest.className, 'dialog-paper', {
                            [slideDirection]: slide,
                            [extraClassName]: !!extraClassName?.length
                        })
                    }}/>
                </div>
            </Draggable>
        </>

    );
}

const Dialog = ({
                    open,
                    setOpen,
                    disableBackdropClick = false,
                    disableEscapeKeyDown = false,
                    loading = false,
                    maxWidth = 'sm',
                    fullWidth = false,
                    fullScreen = false,
                    scroll = 'paper',
                    children,
                    slide = false,
                    slideDirection = 'left',
                    className = '',
                    ...props
                }) => {


    /**
     * Determines if and when to close the modal based on the specified options
     *
     * * if loading => will not close
     * * if disableEscapeKeyDown and the reason for close is of escapeKeyDown => will not close
     * * if disableBackdropClick and the reason for close is of backdropClick => will not close
     * * under any other circumstance => closes the modal
     * @param {"backdropClick" | "escapeKeyDown"} reason
     */
    const onClose = (reason) => {
        if (loading) return;
        switch (reason) {
            case "escapeKeyDown":
                if (disableEscapeKeyDown) return;
                setOpen(false);
                break;
            case "backdropClick":
                if (disableBackdropClick) return;
                setOpen(false);
                break;
            default:
                setOpen(false);
                break;
        }
    }

    return (
        <MuiDialog
            className={classnames('dialog', {'slide-dialog': slide, [slideDirection]: slide})}
            onClose={(e, reason) => onClose(reason)}
            PaperComponent={DraggableComponent}
            PaperProps={{onCloseClick: () => setOpen(false), slide, slideDirection, extraClassName: className}}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            scroll={scroll}
            fullWidth={fullWidth}
            open={open}
            {
                ...(slide
                    ? {
                        TransitionComponent: SlideTransitionComponent,
                        TransitionProps: {direction: slideDirection}
                    }
                    : {})
            }
            {...props}
        >
            {
                children
            }
        </MuiDialog>
    );
}

export default Dialog;
