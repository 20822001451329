import React, {useLayoutEffect, useState} from "react";
import useWindowSize from "../../../../hooks/use_window/useWindowSize";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {
    InputFileAcceptMimes,
    InputFileAcceptTypes,
    WarrantyAndReturnMessageTypes
} from "../../../../../core/constants/enums";
import {dateComparator, numComparator, sendChatFiles} from "../../../../../core/services/utils";
import * as $ from "jquery";
import {chatSectionId} from "../../../../../core/constants/ids";
import TimeBubble from "../../../../components/app-specific/chat_bubble/time_bubble";
import TextBubbles from "../../../../components/app-specific/chat_bubble/text_bubble";
import ImageBubble from "../../../../components/app-specific/chat_bubble/image_bubble";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import DropZone from "../../../../components/base/drop-zone";
import useWindowViewportWidth from "../../../../hooks/use_window/useWindowViewportWidth";
import FileBubble from "../../../../components/app-specific/chat_bubble/file_bubble";

/**
 * Compares the messages based on their order index and submitted date time.
 * @param a {{orderIndex: number, submittedDateTime: Date, msgType: {id:number}}} message
 * @param b {{orderIndex: number, submittedDateTime: Date, msgType: {id:number}}} message
 * @return {number}
 */
const messageComparator = (a, b) => {
    let res = dateComparator(a.submittedDateTime, b.submittedDateTime);
    if (res === 0) {
        res = numComparator(a.orderIndex, b.orderIndex);
    }
    if (res === 0) {
        res = numComparator(b.msgType?.id, a.msgType?.id); // first 3 => Time then 1 => Text
    }
    return res;
}

/**
 * Calculates the height of the inner chats so that the elements stay on the page. The minimum height of the inner
 * chat is half of the window' height.
 * @param {"xs" | "sm" | "md" | "lg" | "xl"} viewportWidth
 * @return {number}
 */
const getHeight = (viewportWidth) => {
    const chatPapers = $(`.chat-paper`);
    let res = window.innerHeight;
    if (!chatPapers?.length) return 0;
    for (let paper of chatPapers) {
        if (paper.classList.contains('main-section')) continue;
        res -= paper?.scrollHeight ?? 0;
    }
    res -= $(`.chat-footer-divider`)?.scrollHeight ?? 0;
    res -= $(`.chat-footer`)?.scrollHeight ?? 0;
    switch (viewportWidth) {
        case "xs":
            res = Math.max((window.innerHeight ?? 0), res);
            res -= 100; // 150 is for all of the vertical paddings and margins of components
            break;
        case "sm":
        case "md":
            res = Math.max((window.innerHeight / 1.5 ?? 0), res);
            res -= 100; // 150 is for all of the vertical paddings and margins of components
            break;
        case "lg":
        case "xl":
        default:
            res = Math.max((window.innerHeight / 3 ?? 0), res);
            res -= 150; // 150 is for all of the vertical paddings and margins of components
            break;
    }
    return res;
}

const ChatMainSection = ({send, sending, chats, resend, createTime, createObject, loading: loadingProp}) => {
    const [loading, setLoading] = useState(false);
    const [height, setHeight] = useState(0);
    const windowSize = useWindowSize();
    const windowViewportWidth = useWindowViewportWidth();
    const {ready} = useTranslation();


    /**
     * Listens for the changes in the size of the window and with each change:
     * if all currents exist, calculates the new height of the body and if it is not the same as the current, sets
     * the state.
     */
    useLayoutEffect(() => {
        if (!ready) return;
        const newHeight = getHeight(windowViewportWidth);
        if (newHeight !== height) {
            setHeight(newHeight);
        }
    }, [windowSize, ready]);

    /**
     * For each of the files, sends them to the server api as a new message one by one.
     * @param images {FileList}
     */
    const sendFiles = (images) => {
        send(sendChatFiles(images, createObject) ?? []);
    }

    /**
     * Inserts the time bubbles into the chat of the user.
     * @return {*[]}
     * @private
     */
    const _insertTimeBubblesIntoChat = () => {
        const sorted = [...chats?.sort(messageComparator) ?? []];
        const copy = [...sorted];
        if (copy.length) {
            const time = createTime((copy[0].submittedDateTime));
            sorted.splice(0, 0, time);
            for (let current = 0, after = 1; after < copy.length && current < copy.length - 1; current++, after++) {
                const afterDateTime = copy[after].submittedDateTime;
                const currentDateTime = copy[current].submittedDateTime;
                if (moment(currentDateTime).isBefore((moment(afterDateTime)), 'day')) {
                    const startIndex = sorted.findIndex((e) => moment(e.submittedDateTime).isSame(moment(currentDateTime)));
                    if (startIndex === -1) continue;
                    const time = createTime(afterDateTime);
                    sorted.splice(startIndex + 1, 0, time);
                }
            }
        }
        return sorted;
    }


    /**
     * Inserts time slots between chats with different dates in terms of days and then creates the chat bubbles
     */
    const createChats = () => {
        const chatContainer = document.getElementById(chatSectionId);
        if (!chats || !chatContainer) return [];
        const _chats = _insertTimeBubblesIntoChat();
        return _chats?.map(data => {
            switch (data.msgType?.id) {
                case WarrantyAndReturnMessageTypes.time:
                    return {
                        key: data.id,
                        component: <TimeBubble data={data}/>
                    };
                case WarrantyAndReturnMessageTypes.text:
                    return {
                        key: data._reactKey,
                        component: <TextBubbles data={data} sendAgain={resend} container={chatContainer}/>,
                    }
                case WarrantyAndReturnMessageTypes.image:
                    return {
                        key: data._reactKey,
                        component: <ImageBubble data={data} sendAgain={resend} container={chatContainer}/>,
                    }
                case WarrantyAndReturnMessageTypes.pdf:
                case WarrantyAndReturnMessageTypes.video:
                    return {
                        key: data._reactKey,
                        component: <FileBubble typeId={data.msgType?.id} data={data} sendAgain={resend}
                                               container={chatContainer}/>,
                    }
                case WarrantyAndReturnMessageTypes.forwardedMessage:
                    switch (data.forwardedMsgInfo.typeID) {
                        case WarrantyAndReturnMessageTypes.text:
                            return {
                                key: data._reactKey,
                                component: <TextBubbles
                                    data={data}
                                    sendAgain={resend}
                                    container={chatContainer}
                                />,
                            }
                        case WarrantyAndReturnMessageTypes.image:
                            return {
                                key: data._reactKey,
                                component: <ImageBubble
                                    data={data}
                                    sendAgain={resend}
                                    container={chatContainer}
                                />,
                            }
                        case WarrantyAndReturnMessageTypes.pdf:
                        case WarrantyAndReturnMessageTypes.video:
                            return {
                                key: data._reactKey,
                                component: <FileBubble typeId={data.msgType?.id} data={data} sendAgain={resend}
                                                       container={chatContainer}/>,
                            }
                        default:
                            return null;
                    }
                default:
                    return null;
            }
        })?.filter(e => e)?.map((e) => {
            return (
                <CSSTransition
                    key={e.key}
                    timeout={500}
                    classNames="css-item"
                >
                    {e.component}
                </CSSTransition>
            )
        });
    }

    return (
        <DropZone
            disabled={sending || loading}
            loading={loading}
            setLoading={setLoading}
            identifier={'chat'}
            multiple
            accept={[
                InputFileAcceptMimes.images,
                InputFileAcceptMimes.videos,
                InputFileAcceptTypes.pdf
            ]}
            id={'chat-drop-zone'}
            noParse={true}
            onSuccess={sendFiles}
            openOnClick={false}
            dropZoneClassName={'chat-drop-zone'}
            className={`chat-upload-button`}
        >
            <TransitionGroup id={chatSectionId} style={{height: height}} className={'w-100'}>
                {
                    loadingProp
                        ? <CSSTransition
                            key={"loading"}
                            timeout={300}
                            classNames="css-item"
                        >
                            <div className={'loading-div chat'}>
                                <div/>
                            </div>
                        </CSSTransition>
                        : createChats()
                }
            </TransitionGroup>
        </DropZone>
    );
}

export default ChatMainSection;
