export const apiUrl = process.env.REACT_APP_API_URL;

/**
 * PartSupportVendorsMsg Apis
 */
const warrantyAndReturnBaseUrl = apiUrl + 'PartSupportVendorsMsg/';
export const warrantyAndReturnApis = {
    baseUrl: warrantyAndReturnBaseUrl,
    getInformation: (vendorToken: string) => warrantyAndReturnBaseUrl + `GetAllVendorMessagesForVendors/${vendorToken}`,
    sendMessage: warrantyAndReturnBaseUrl + 'SendMessage_Vendor',
}
