import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import routes from "./ui/routes";
import {Flip, toast, ToastContainer} from "react-toastify";
import Landing from "./ui/views";
import {MuiThemeProvider} from "@material-ui/core";
import theme from "./assets/css/mui-theme";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";


const App = () => {
    return (
        <>
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Router>
                        <Route path={routes.public.landing}>
                            <Landing/>
                        </Route>
                    </Router>
                    <ToastContainer
                        position={toast.POSITION.TOP_RIGHT}
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        pauseOnFocusLoss={false}
                        draggable
                        pauseOnHover
                        transition={Flip}
                    />
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </>
    );
}

export default App;
