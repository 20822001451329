import React, {useEffect} from "react";
import routes from "../../routes";
import {matchPath, Route, Switch} from "react-router";
import useRouter from "../../hooks/use_router";
import WarrantyAndReturnChatView from "./warranty-and-return-chat";

const PublicViews = () => {
    const {location, history} = useRouter();

    /**
     * Listens for the changes in pathname of the url and with each change:
     * if the pathname is landing, then it must be replaced with the warrantyAndReturnChat view
     */
    useEffect(() => {
        if (matchPath(location.pathname, {path: routes.public.landing, exact: true})) {
            history.replace(routes.error.notFound);
        }
    }, [location.pathname])

    return (
        <Switch>
            <Route path={[routes.public.warrantyAndReturnChat]} exact>
                <WarrantyAndReturnChatView/>
            </Route>
        </Switch>
    );
}

export default PublicViews;
