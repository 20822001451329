import moment from "moment";
import {jsonToCSV} from 'react-papaparse'
import {
    InputFileAcceptMimes,
    InputFileAcceptTypes,
    ReadingFileAs,
    WarrantyAndReturnMessageTypes
} from "../constants/enums";
import accepts from "attr-accept";

/**
 * Determines if two objects are equal
 * @param object1 {any}
 * @param object2 {any}
 * @return {boolean}
 */
export const deepEqual = (object1: any, object2: any): boolean => {
    // check if the first one is an array
    if (Array.isArray(object1)) {
        if (!Array.isArray(object2) || object1.length !== object2.length) return false;
        for (let i = 0; i < object1.length; i++) {
            if (!deepEqual(object1[i], object2[i])) return false;
        }
        return true;
    }
    // check if the first one is an object
    if (typeof object1 === 'object' && object1 !== null && object2 !== null) {
        if (!(typeof object2 === 'object')) return false;
        const keys = Object.keys(object1);
        if (keys.length !== Object.keys(object2).length) return false;
        for (const key in object1) {
            if (!deepEqual(object1[key], object2[key])) return false;
        }
        return true;
    }
    // not array and not object, therefore must be primitive
    return object1 === object2;
}

/**
 *  Deep copy an acyclic *basic* Javascript object.  This only handles basic
 * scalars (strings, numbers, booleans) and arbitrarily deep arrays and objects
 * containing these.  This does *not* handle instances of other classes.
 * @param obj {any}
 */
export const deepCopy = (obj: any): any => {
    let ret, key;
    let marker = '__deepCopy';

    if (obj && obj[marker])
        throw (new Error('attempted deep copy of cyclic object'));

    if (obj && obj.constructor == Object) {
        ret = {};
        obj[marker] = true;

        for (key in obj) {
            if (key == marker)
                continue;

            // @ts-ignore
            ret[key] = deepCopy(obj[key]);
        }

        delete (obj[marker]);
        return (ret);
    }

    if (obj && obj.constructor == Array) {
        ret = [];
        // @ts-ignore
        obj[marker] = true;

        for (key = 0; key < obj.length; key++)
            ret.push(deepCopy(obj[key]));

        // @ts-ignore
        delete (obj[marker]);
        return (ret);
    }
    // It must be a primitive type -- just return it.
    return (obj);
}

/**
 * Transforms a string that is parsable to a number into a formatted money string.
 * @param amount {string | number}
 * @param decimalCount {number}
 * @param decimal {string} the identifier used for decimal separation
 * @param thousands {string} the identifier used for thousands separation
 */
export const formatMoney = (amount: any, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";
        amount = Math.abs(Number(amount) || 0).toFixed(decimalCount);
        let i: any = parseInt(amount).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return "$" + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

/**
 * Flattens an object. if the parent key exists, then prepends the parent key with the key as it constructs the obejct
 * @param object {any}
 * @param parentKey {string | null}
 */
const _flatten = (object: any, parentKey: string | null = null): any => {
    return [].concat(...Object.keys(object)
        .map((key) => typeof object[key] === 'object'
            ? _flatten(object[key], parentKey ? `${parentKey}-${key}` : key)
            : ((parentKey) ? {[`${parentKey}-${key}`]: object[key]} : {[key]: object[key]})
        )
    );
}

/**
 * Creates a new flattened object off of the given object
 * @param object {any}
 */
export const flattenObject = (object: any) => {
    return Object.assign({}, ..._flatten(object))
}

/**
 * Given an object, will flatten it and return all of its values as a single.
 *
 * if append, then for each of the values of the object, appends it to their values as a string
 * @param object {any}
 * @param append {string | null}
 */
export const flattenObjectAndReturnAsAList = (object: any, append: string | null = null): string[] | any[] => {
    const all = flattenObject(object);
    const res = [];
    for (const [key, value] of Object.entries(all)) {
        if (key) res.push(value);
    }
    if (append && append.length) return res.map(e => `${e}${append}`);
    return res;
};

/**
 * Gets the offset of an element based on the parent id
 * @param element {HTMLElement | Element | null}
 * @param parentId {string}
 */
export const getOffsetTop = (element: HTMLElement | Element | null, parentId: string) => {
    let offsetTop = 0;
    while (element && element.id !== parentId) {
        offsetTop += (element as HTMLElement).offsetTop;
        element = (element as HTMLElement).offsetParent;
    }
    return offsetTop;
}

/**
 * Gets the element position with resposne to the window
 * @param element {HTMLElement}
 */
export const getElementPositionWithRespectToWindow = function (element: HTMLElement) {
    let target = element,
        target_width = target.offsetWidth,
        target_height = target.offsetHeight,
        globalLeft = 0,
        globalTop = 0,
        rect = {};

    /**
     * if the element has a parent, then add the parents; offset left and top the global one, otherwise return the
     * rect object of the element position
     * @param _parent {HTMLElement}
     */
    const moonwalk = function (_parent: any) {
        if (!!_parent) {
            globalLeft += _parent.offsetLeft;
            globalTop += _parent.offsetTop;
            moonwalk(_parent.offsetParent);
        } else {
            return rect = {
                top: target.offsetTop + globalTop,
                left: target.offsetLeft + globalLeft,
                bottom: (target.offsetTop + globalTop) + target_height,
                right: (target.offsetLeft + globalLeft) + target_width
            };
        }
    };
    moonwalk(target.offsetParent);
    return rect;
}

/**
 * Determines if a given target is within the vicinity of a central point based on the specified percentage
 * @param target {number}
 * @param center {number}
 * @param percent {number}
 * @param threshHold {number}
 */
export const withinVicinity = (target: number, center: number, percent: number = 0, threshHold: number = 0): boolean => {
    const difference = Math.abs(center - target);
    return (Math.abs(center) + ((percent / 100) * Math.abs(center)) - threshHold) >= difference;
}

/**
 * Exports a file into csv by the given headers, items and the file title
 * @param headers {any}
 * @param items {any}
 * @param fileTitle {string}
 */
export const exportCSVFile = (headers: any, items: any, fileTitle: string): void => {
    const csv = jsonToCSV(items, {});
    const exportedFileName = fileTitle + '.csv' || 'export.csv';
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFileName);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            link.href = URL.createObjectURL(blob);
            link.download = exportedFileName;
            link.style.visibility = 'hidden';
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

/**
 * Reads the given file based on the provided method of reading.
 * @param {File | Blob} file
 * @param {string} as
 */
export const readFile = (file: File | Blob, as: string): PromiseLike<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        switch (as) {
            case ReadingFileAs.text:
                reader.readAsText(file);
                break;
            case ReadingFileAs.dataUrl:
                reader.readAsDataURL(file);
                break;
            case ReadingFileAs.arrayBuffer:
                reader.readAsArrayBuffer(file);
                break;
            case ReadingFileAs.binaryString:
                reader.readAsBinaryString(file);
                break;
        }
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

/**
 * fills the card number empty spaces with *s
 * @param lastFour
 */
export const getCardNumber = (lastFour: string) => {
    return `**** - **** - ${lastFour}`;
}

/**
 * fills the card month empty spaces with *s
 * @param month
 */
export const getCardMonth = (month: string) => {
    if (month.length === 1) {
        return `0${month}/**`;
    }
    return `${month}/**`;
}

/**
 * Sets Attributes for a given element.
 * @param element
 * @param attributes
 */
export const setAttributes = (element: any, attributes: any) => {
    for (const [key, value] of Object.entries(attributes)) {
        if ((key === 'styles' || key === 'style') && typeof value === 'object') {
            for (const [styleKey, styleProp] of Object.entries(value as any)) {
                element.style[styleKey] = styleProp;
            }
        } else if (value === 'html') {
            element.innerHTML = value;
        } else {
            element.setAttribute(key, value);
        }
    }
}

type ObjectKey = string | number | symbol;

/**
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param items An array of type TItem.
 * @param keyGetter A Function that takes the the Array type TItem as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of TItem.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export const groupBy = <K extends ObjectKey, TItem extends Record<K, ObjectKey>>(
    items: TItem[],
    keyGetter: (input: TItem) => K
): Record<ObjectKey, TItem[]> => {
    return items.reduce((result, item) => ({
        ...result,
        [keyGetter(item)]: [
            ...(result[keyGetter(item)] || []),
            item
        ],
    }), {} as Record<ObjectKey, TItem[]>);
}

/**
 * Calculates the area of a circle given its radius.
 * @param r {number}
 */
export const areaOfCircle = (r: number) => r * r * 3.1415;

/**
 * Creates a Unique Identifier in form of a string
 */
export const createUUId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const randomNumber = Math.random() * 16 | 0;
        const v = c == 'x' ? randomNumber : (randomNumber & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * Exports a file into json given items and the file title
 * @param items {any}
 * @param fileTitle {string}
 */
export const exportJsonFile = (items: any, fileTitle: string): void => {
    const exportedFileName = fileTitle + '.json' || 'template.json';
    const blob = new Blob([items], {type: 'data:application/json;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFileName);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            link.href = URL.createObjectURL(blob);
            link.download = exportedFileName;
            link.style.visibility = 'hidden';
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

/**
 *  Returns the color as an array of [r, g, b, a] -- all range from 0 - 255
 * color must be a valid canvas fillStyle. This will cover most anything
 * you'd want to use.
 * Examples:
 * _colorToRGBA('red')  # [255, 0, 0, 255]
 * _colorToRGBA('#f00') # [255, 0, 0, 255]
 * @param {string} color
 */
const _colorToRGBA = (color: string) => {
    let cvs, ctx: any;
    cvs = document.createElement('canvas');
    cvs.height = 1;
    cvs.width = 1;
    ctx = cvs.getContext('2d');
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 1, 1);
    return ctx.getImageData(0, 0, 1, 1).data;
}

/**
 * Turns a number (0-255) into a 2-character hex number (00-ff)
 * @param {number} num
 */
const _byteToHex = (num: number) => {
    return ('0' + num.toString(16)).slice(-2);
}

/**
 * Convert any CSS color to a hex representation
 *  Examples:
 * colorToHex('red')            # '#ff0000'
 * colorToHex('rgb(255, 0, 0)') # '#ff0000'
 * @param {string} color
 */
export const colorToHex = (color: string) => {

    let rgba: any, hex: any;
    rgba = _colorToRGBA(color);
    hex = [0, 1, 2].map(
        function (idx) {
            return _byteToHex(rgba[idx]);
        }
    ).join('');
    return "#" + hex;
}

/**
 * Returns the given value with the maximum amount of decimals provided.
 * @param value
 * @param maxDecimals
 * @param returnType
 */
export const numberWithMaximumDecimals = (value: string | number, maxDecimals: number = 2, returnType: "string" | "number" = 'string'): number | string => {
    // @ts-ignore
    const modulus = value % 1;
    if (modulus == 0) {
        return value;
    }
    const modulusString = value.toString();

    const numberOfDecimals = modulusString.split('.')?.length > 1
        ? modulusString.split('.')[1].length
        : 0;
    const valueWithCorrectDecimals = typeof value === 'string'
        ? parseFloat(value).toFixed(Math.min(numberOfDecimals, maxDecimals))
        : value.toFixed(Math.min(numberOfDecimals, maxDecimals));

    switch (returnType) {
        case 'string':
            return valueWithCorrectDecimals;
        case "number":
        default:
            return parseFloat(valueWithCorrectDecimals);
    }
}

/**
 * Given a string, forces a number value from it. If NAN, returns 0.
 * @param {string| number} val
 * @return {number}
 */
export const forceNumber = (val: number | string): number => {
    if (typeof val === 'number') return val;
    const _value = parseFloat(val ?? '0');
    return isNaN(_value) ? 0 : _value;
}

/**
 * Converts the given css values to pixel values.
 * @param {{name: string, value: any}[]} cssValues
 * @return {{name: string, value: number}[]}
 */
export const calculateInPx = (cssValues: { name: string, value: any }[]) => {
    const result: { name: string, value: any }[] = [];
    if (!cssValues?.length) return result;
    const testElement = document.createElement('testPageSize');
    cssValues?.forEach((cssValue: { name: string, value: any }) => {
        // @ts-ignore
        testElement.style[cssValue.name] = cssValue.value;
    })
    document.documentElement.appendChild(testElement);
    cssValues.forEach((cssValue: { name: string, value: any }, index: number) => {
        result[index] = {
            name: cssValue.name,
            // @ts-ignore
            value: window.Extensions.toPx(testElement, testElement.style[cssValue.name], cssValue.name)
        }
    })
    document.documentElement.removeChild(testElement);
    return result;
}

/**
 * Opens a new tab in the users' browser with the given url.
 * @param url
 */
export const openInNewTab = (url: string) => {
    const newTab = document.createElement('a');
    newTab.href = url;
    newTab.target = '_blank';
    newTab.rel = 'noopenner';
    document.body.appendChild(newTab);
    newTab.click();
    document.body.removeChild(newTab);
}


/**
 * For each of the files, sends them to the server api as a new message one by one.
 * @param images {FileList}
 * @param createObject {Function}
 */
export const sendChatFiles = (images: FileList, createObject: Function) => {
    if (!images?.length) return;
    const forSend = [];
    for (let index = 0; index < images.length; ++index) {
        let type;
        const item: any = images.item(index)
        if (accepts(item, InputFileAcceptMimes.images)) {
            type = WarrantyAndReturnMessageTypes.image
        } else if (accepts(item, InputFileAcceptTypes.pdf)) {
            type = WarrantyAndReturnMessageTypes.pdf
        } else if (accepts(item, InputFileAcceptMimes.videos)) {
            type = WarrantyAndReturnMessageTypes.video
        }
        forSend.push(createObject(item, type, index))
    }
    return forSend;
}

/**
 * Opens the provided link into a new tab of the browser
 * @param blob {Blob} the blob to download
 * @param fileName {string} the name of the file to download
 */
export const downloadFile = (blob: Blob, fileName = null) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName ?? 'unknown';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}


//              ########################### COMPARATORS ###################################

/**
 * Compares two numbers
 * @param a {number}
 * @param b {number}
 */
export const numComparator = (a: number, b: number): number => {
    if (a === b) return 0;
    if (a < b) return -1;
    return 1
}

/**
 * Compares two dates by converting them to moment objects and then comparing them
 * @param a {Date}
 * @param b {Date}
 */
export const dateComparator = (a: Date, b: Date): number => {
    const _momentComparator = (a: moment.Moment, b: moment.Moment) => {
        if (a.isSame(b, 'ms')) return 0;
        if (a.isAfter(b, 'ms')) return 1;
        return -1;
    }
    return _momentComparator(moment(a), moment(b));
}

/**
 * Compares two strings.
 * @param a {string}
 * @param b {string}
 */
export const stringComparator = (a: string, b: string): number => {
    return a?.localeCompare(b);
}

/**
 * Compares two Booleans
 * @param a {boolean}
 * @param b {boolean}
 */
export const booleanComparator = (a: boolean, b: boolean): number => {
    if (a === b) return 0;
    if (a < b) return -1;
    return 1;
}
