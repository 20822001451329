export default class LocalStorageService {
    static keys = {
        token: "bt",
        cachedUrl: 'curl',
        tokenExpiration: "te",
        userFirstName: "ufn",
        userLastName: "uln",
        userEmail: "ue",
        settings: 'ss',
        defaultSettings: 'dss',
    }

    /**
     * Saves data into local storage
     */
    static set = (name: string, value: string) => {
        return localStorage.setItem(name, value);
    };

    /**
     * Extracts the data from local storage
     */
    static get = (name: string | string[]): any | Array<any> => {
        if (typeof name === "string") {
            return localStorage[name];
        }
        if (Array.isArray(name)) {
            return name.map(e => localStorage[e]);
        }
    };

    /**
     * Removes the key/value pair with the given key from the list associated with the object,
     * if a key/value pair with the given key exists.
     */
    static remove = (name: string) => {
        return localStorage.removeItem(name);
    }

    /**
     * Returns the current value associated with the given key, or null if the given key does not exist in the single
     * associated with the object.
     */
    static removeAll = () => {
        return localStorage.clear();
    }

    /**
     * Returns all of the available data from the local storage
     */
    static getAll = () => {
        const keys = LocalStorageService.keys;
        const get = LocalStorageService.get;
        let result: any = {};
        for (let key in keys) {
            result[key] = get(key);
        }
        return result;
    }
}


