import {createTheme} from "@material-ui/core";
import {
    disabledTextColor,
    green,
    greenLight,
    hintTextColor,
    primaryColor,
    primaryColorDark,
    primaryColorLight,
    primaryTextColor,
    red,
    redLight,
    secondaryColor,
    secondaryColorDark,
    secondaryColorLight,
    secondaryColorLighter,
    secondaryTextColor,
    white,
} from "./variables/colors";


const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200
        },
    },
    palette: {
        primary: {
            light: primaryColorLight,
            dark: primaryColorDark,
            main: primaryColor,
        },
        secondary: {
            light: secondaryColorLight,
            dark: secondaryColorDark,
            main: secondaryColor,
        }
        ,
        success: {
            main: green,
            light: greenLight,
        }
        ,
        error: {
            main: red,
            light: redLight,
        },
        text: {
            primary: primaryTextColor,
            secondary: secondaryTextColor,
            disabled: disabledTextColor,
            hint: hintTextColor,
        }
    },
    typography: {
        fontFamily: 'Montserrat',
        fontSize: 13,
    },
    shape: {
        borderRadius: 7,
    },
    overrides: {
        MuiInputBase: {
            root: {
                fontSize: '0.8rem',
                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                },
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: primaryColorLight,
                '&:hover': {
                    borderColor: primaryColor,
                }
            },
            multiline: {
                padding: '4.5px 0',
            }
        },
        MuiFormControl: {
            root: {
                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                },
            },
        },
        MuiSelect: {
            root: {
                padding: '16px 16px'
            },
            select: {
                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                    color: disabledTextColor,
                },
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            },
            outlined: {
                paddingRight: '15px',
                paddingLeft: 15,
            }
        },
        MuiButton: {
            root: {
                padding: '0.5rem',
                border: 'none',
                outline: 'none',
            },
        },
        MuiPopover: {
            paper: {
                elevation: 4,
            },
            root: {
                elevation: 4,
            }
        },
        MuiIconButton: {
            root: {
                padding: 5,
            },

        },
        MuiMenu: {
            paper: {
                marginTop: 5,
                borderRadius: 3,
            }
        },
        MuiMenuItem: {
            root: {
                transition: 'all 0.2s ease-in-out',
                color: primaryColorDark,
                margin: '5px 5px',
                borderRadius: 3,
                paddingBottom: 11,
                paddingTop: 11,
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: white,
                }
            },

        },
        MuiFormHelperText: {
            root: {
                '&$error': {
                    fontWeight: 500,
                    fontSize: '12px !important',
                    margin: '8px 0',
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 5,
                padding: 10,
                fontWeight: 600,
                fontSize: 12,
            }
        },
        MuiAutocomplete: {
            option: {
                margin: '10px',
                borderRadius: 7,
                '&$Mui-select': {
                    backgroundColor: secondaryColorLighter,
                },
                '&:hover': {
                    backgroundColor: secondaryColorLighter,
                },
            }
        },
    },

});


export default theme;
