import React, {useRef, useState} from "react";
import {UploadStates} from "../../../../core/constants/enums";
import IconButton from "../../base/button/icon-button";
import {CircularProgress, Fade} from "@material-ui/core";
import {Autorenew, CheckCircleOutline, ErrorOutline} from "@material-ui/icons";
import classnames from "classnames";

const ImageBubble = ({data, sendAgain, container}) => {
    const [sending, setSending] = useState(false);
    const [hover, setHover] = useState(false);
    const ref = useRef(null);

    const isFromCurrent = !data.operator;
    const forwardedData = data.forwardedMsgInfo;


    /**
     * Determines whether the current image is portrait or
     * @return {boolean}
     */
    const isPortrait = () => {
        const image = ref?.current;
        if (!image) return true;
        return image.clientWidth < image.clientHeight;
    }

    return (
        <div
            id={`${forwardedData?.submitDateTime?.toString() ?? data.submittedDateTime?.toString()}-${forwardedData?.typeID ?? data.msgType.id}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={classnames(`chat-bubble`, {'currentUser': isFromCurrent})}
        >

            <Fade
                in={data.uploadState?.state === UploadStates.error && hover}
                unmountOnExit mountOnEnter>
                <div className={'d-flex flex-column justify-content-center me-1'}>
                    <IconButton
                        className={'me-2'}
                        disabled={sending}
                        aria-label={'Resend message'}
                        onClick={() => sendAgain(data, setSending)}
                    >
                        <Autorenew className={'icon normal'}/>
                    </IconButton>
                </div>
            </Fade>
            <div
                className={classnames('bubble',
                    {
                        'currentUser': isFromCurrent,
                        'pending': data.uploadState?.state === UploadStates.pending,
                        'error': data.uploadState?.state === UploadStates.error,

                    })}
                style={{maxWidth: Math.max((container?.clientWidth ?? 200) / 3, 300)}}
            >
                <img
                    ref={ref}
                    src={
                        isFromCurrent &&
                        (data.uploadState?.state === UploadStates.pending || data.uploadState?.state === UploadStates.error)
                            ? data.tempUrl
                            : forwardedData?.data ?? data.data
                    }
                    alt={`message-at-${data.forwardedData?.submitDateTime?.toString() ?? data.submittedDateTime.toString()}`}
                    className={classnames(`text-sm`, {
                        [isFromCurrent ? "white" : "text-color-1"]: true,
                        [isPortrait() ? 'portrait' : 'landscape']: true,
                    })}
                />
            </div>
            {
                isFromCurrent
                && (
                    <div className={'d-flex flex-column align-items-end justify-content-end me-1'}>
                        <Fade
                            in={(data.uploadState?.state === UploadStates.error)}
                            unmountOnExit mountOnEnter>
                            <ErrorOutline className={'icon fill-red sm'}/>
                        </Fade>
                        <Fade in={data.uploadState?.state === UploadStates.done}
                              unmountOnExit mountOnEnter>
                            <CheckCircleOutline className={'icon stroke-grey-light sm'}/>
                        </Fade>
                        <Fade
                            in={(data.uploadState?.state === UploadStates.pending)}
                            unmountOnExit mountOnEnter>
                            <CircularProgress color={'secondary'} size={15}/>
                        </Fade>
                    </div>
                )
            }
        </div>
    );
}

export default ImageBubble;
