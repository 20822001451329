import React, {useState} from "react";
import {
    InputFileAcceptMimes,
    InputFileAcceptTypes,
    WarrantyAndReturnMessageTypes
} from "../../../../../core/constants/enums";
import {IconButton, UploadButton} from "../../../../components/base/button";
import {AttachFile, Telegram} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {sendChatFiles} from "../../../../../core/services/utils";

const ChatFooter = ({sending, send, createObject}) => {
    const {t} = useTranslation();
    const [text, setText] = useState('');

    /**
     * Sends the text as a new message, and clears the input.
     * @param text
     */
    const sendText = (text) => {
        if (!text?.length) return;
        setText('');
        const forSend = [
            createObject(text, WarrantyAndReturnMessageTypes.text, 0),
        ];
        send(forSend);
    }

    /**
     * For each of the files, sends them to the server api as a new message one by one.
     * @param images {FileList}
     */
    const sendFiles = (images) => {
        send(sendChatFiles(images, createObject) ?? []);
    }

    return (
        <>
            <div className={'chat-footer-divider'}/>
            <div className={'chat-footer'}>
                <UploadButton
                    labelClassName={'mb-0'}
                    disabled={sending}
                    accept={[InputFileAcceptMimes.images, InputFileAcceptMimes.videos, InputFileAcceptTypes.pdf]}
                    multiple
                    id={'chat-upload-file'}
                    onFileSelect={sendFiles}>
                    <IconButton
                        aria-label={'chat upload file'}
                        component={'span'}>
                        <AttachFile className={'icon md'}/>
                    </IconButton>
                </UploadButton>
                <input
                    className={'chat-input'}
                    value={text}
                    onChange={e => setText(e.target.value)}
                    placeholder={t('views.warranty-and-return-chat.chatInputPlaceholder')}
                    onKeyDown={e => e.key === 'Enter' && sendText(e.target.value)}
                />
                <IconButton
                    disabled={text?.length <= 0}
                    aria-label={'send new message'}
                    onClick={() => sendText(text)}
                >
                    <Telegram className={'icon md'}/>
                </IconButton>
            </div>
        </>
    );
}

export default ChatFooter;
