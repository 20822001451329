//    ***** CSS IMPORTS *******
import './assets/css/index.scss';
import "react-toastify/dist/ReactToastify.css";
//    ***** Other IMPORTS *******
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';
import setUpJQueryExtensions from "./core/services/jquery_extensions";
import setUpExtensions from "./core/services/extensions";

import './i18n.config';

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
setUpJQueryExtensions();
setUpExtensions();
reportWebVitals();

