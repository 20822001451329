import React, {useState} from "react";
import {FilePreviewTypes, UploadStates, WarrantyAndReturnMessageTypes} from "../../../../core/constants/enums";
import IconButton from "../../base/button/icon-button";
import {CircularProgress, Fade} from "@material-ui/core";
import {Autorenew, CheckCircleOutline, ErrorOutline} from "@material-ui/icons";
import classnames from "classnames";
import {ReactComponent as Pdf} from "../../../../assets/images/warranty-files/pdf.svg";
import {ReactComponent as Video} from "../../../../assets/images/warranty-files/video.svg";
import {ReactComponent as Unknown} from "../../../../assets/images/warranty-files/php.svg";
import FilePreviewDialog from "../../dialogs/file-preview-dialog";

const FileBubble = ({data, sendAgain, container, typeId}) => {
    const [sending, setSending] = useState(false);
    const [hover, setHover] = useState(false);
    const [preview, setPreview] = useState({open: false, data: null});


    const isFromCurrent = !data.operator;
    const forwardedData = data.forwardedMsgInfo;


    /**
     * Gets the file image appropriate to the type of the file.
     * @return {JSX.Element}
     */
    const getFile = () => {
        switch (typeId) {
            case WarrantyAndReturnMessageTypes.pdf:
                return <Pdf className={'file-icon'}/>;
            case WarrantyAndReturnMessageTypes.video:
                return <Video className={'file-icon'}/>;
            default:
                return <Unknown className={'file-icon'}/>;
        }
    }

    /**
     * Fetceh the preview data of the file
     * @return {{fileName, type: string, url}}
     */
    const getPreviewData = () => {
        let type;
        switch (typeId) {
            case WarrantyAndReturnMessageTypes.pdf:
                type = FilePreviewTypes.pdf;
                break;
            case WarrantyAndReturnMessageTypes.video:
                type = FilePreviewTypes.video;
                break;
            //
            default:
                type = null;
                break;
        }
        return {
            url: data.data,
            type: type,
            fileName: data.fileName,
        }
    }

    return (
        <>
            <div
                id={`${forwardedData?.submitDateTime?.toString() ?? data.submittedDateTime?.toString()}-${forwardedData?.typeID ?? data.msgType.id}`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={classnames(`chat-bubble`, {'currentUser': isFromCurrent})}
            >

                <Fade
                    in={data.uploadState?.state === UploadStates.error && hover}
                    unmountOnExit mountOnEnter>
                    <div className={'d-flex flex-column justify-content-center me-1'}>
                        <IconButton
                            className={'me-2'}
                            disabled={sending}
                            aria-label={'Resend message'}
                            onClick={() => sendAgain(data, setSending)}
                        >
                            <Autorenew className={'icon normal'}/>
                        </IconButton>
                    </div>
                </Fade>
                <div
                    onClick={() => data.uploadState?.state === UploadStates.done && setPreview({
                        open: true,
                        data: getPreviewData()
                    })}
                    className={classnames('bubble file',
                        {
                            'currentUser': isFromCurrent,
                            'pending': data.uploadState?.state === UploadStates.pending,
                            'error': data.uploadState?.state === UploadStates.error,

                        })}
                >
                    <div className={'d-flex align-items-center'}>
                        {getFile()}
                        <p className={`text-sm text-start text-wrap ${isFromCurrent ? 'white' : 'text-color-1'}`}>
                            {data?.fileName ?? ''}
                        </p>
                    </div>
                </div>
                {
                    isFromCurrent
                    && (
                        <div className={'d-flex flex-column align-items-end justify-content-end me-1'}>
                            <Fade
                                in={(data.uploadState?.state === UploadStates.error)}
                                unmountOnExit mountOnEnter>
                                <ErrorOutline className={'icon fill-red sm'}/>
                            </Fade>
                            <Fade in={data.uploadState?.state === UploadStates.done}
                                  unmountOnExit mountOnEnter>
                                <CheckCircleOutline className={'icon stroke-grey-light sm'}/>
                            </Fade>
                            <Fade
                                in={(data.uploadState?.state === UploadStates.pending)}
                                unmountOnExit mountOnEnter>
                                <CircularProgress color={'secondary'} size={15}/>
                            </Fade>
                        </div>
                    )
                }
            </div>
            <FilePreviewDialog
                setOpen={(open) => setPreview({open: open, data: null})}
                open={preview.open}
                data={preview.data}
            />
        </>

    );
}

export default FileBubble;
