import axios, {AxiosResponse} from 'axios';

import LocalStorageService from './local_storage_service'
import Texts from "../constants/texts/error_messages";
import ApiRequestDs from "../models/requests/api_request.ds";
import CrudDs from "../models/response/crud.ds";
import {History, Location} from 'history';
import {toast} from "react-toastify";
import CacheService from "./cache_service";

/**
 * Makes an api request using the provided request data and returns a proper result based on the result of the api
 * response.
 * @param apiRequestDs the request of the api
 */
const apiCallback = async <T>(apiRequestDs: ApiRequestDs): Promise<undefined | CrudDs<T | null>> => {
    const {
        url = '',
        method = 'GET',
        body = null,
        history = null,
        location = null,
        headers = {},
        showErrorToast = true,
        showSuccessToast = false,
        redirectToError = false,
        onDownloadProgress = undefined,
        onUploadProgress = undefined,
        loginRequired = true,
    } = apiRequestDs;

    // Checks to see if the user is logged in internally, and if not, logs them out
    const isLoggedIn = CacheService.isLoggedIn();
    if (!isLoggedIn && loginRequired) unAuthorizedResponse(location, history);
    const allHeaders = await _getHeaders(headers);

    try {
        const response: AxiosResponse<CrudDs<T>> = await axios.request<any, AxiosResponse<CrudDs<T>>>({
            url,
            method,
            onDownloadProgress,
            onUploadProgress,
            data: body,
            headers: allHeaders,
        });
        return checkResData<T>(response, history, location, showSuccessToast, showErrorToast, redirectToError);
    } catch (e) {
        // if the api call crashes itself
        const error = Error(`Api Error Internally : ${e}`);
        console.error(error)
        return checkResData<T>(undefined, history, location, showSuccessToast, showErrorToast, redirectToError);
    }
};

/**
 * Gets the headers required for the api call
 * @param headers the extra headers
 */
const _getHeaders = async (headers: any) => {
    return {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        Authorization: LocalStorageService.get(LocalStorageService.keys.token),
        ...headers,
    };
}

/**
 * Checks the response data form the api. if the status if not 200 or the errorCode of the response object is not
 * 200, then logs the error, sends data to sentry and returns the erroneous response.
 * @param response the axios response
 * @param history the react-router-dom history object
 * @param location the react-router-dom location object
 * @param showSuccess whether show success toast
 * @param showError whether show error toast
 * @param redirectToError whether redirect to the an error page upon failed state
 */
const checkResData = <T>(response: AxiosResponse<CrudDs<T>> | undefined,
                         history?: History | null,
                         location?: Location | null,
                         showSuccess: boolean = false,
                         showError: boolean = false,
                         redirectToError: boolean = false): CrudDs<T | null> | undefined => {
    // Catch axios api call error
    if (!response) {
        if (showError) toast.error(`${Texts.serverErrorTitle} : ${Texts.serverErrorMessage}`);
        if (redirectToError && history && location) {
            navigateToServerErrorPage(history, location);
        }
        return undefined;
    }
    // successful result
    if (response.status.toString().startsWith('2') && !response.data.errorCode) {
        if (showSuccess) {
            const successMessage = `${response.data?.headerTitle ?? Texts.serverSuccessTitle} : ${response.data?.message ?? Texts.serverSuccessMessage}`;
            toast.success(successMessage, {type: 'success'});
        }
        return response.data;// successful result
    }
    // error cases
    else {
        switch (response.status) {
            // unAuthorized request
            case 401:
                unAuthorizedResponse(location, history);
                break;
            // access denied
            case 403:
                if (redirectToError && history && location) {
                    navigateToAccessDeniedPage(history, location)
                }
                break;
            case 200:
                switch (response.data.errorCode) {
                    // unAuthorized request internally
                    case 401:
                        unAuthorizedResponse(location, history);
                        break;
                    // access denied internally
                    case 403:
                        if (redirectToError && history && location) {
                            navigateToAccessDeniedPage(history, location)
                        }
                        break;
                    default:
                        const error = Error(`Api Result Failed with ${(response?.status ?? response?.data?.errorCode ?? -100)} and response of ${response?.data}`);
                        console.error(error);
                        if (redirectToError && history && location) {
                            navigateToServerErrorPage(history, location);
                        }
                        break;
                }
                break;
            default:
                break;
        }
        if (showError) {
            const errorMessage = `${response.data?.headerTitle ?? Texts.serverErrorTitle} : ${response.data?.message ?? Texts.serverErrorMessage}`;
            toast.error(errorMessage, {type: 'error'});
        }
        return {
            resultFlag: response.data?.resultFlag ?? false,
            data: response.data?.data ?? null,
            message: response.data?.message ?? Texts.serverErrorMessage,
            headerTitle: response.data?.headerTitle ?? Texts.serverErrorTitle,
            errorCode: response.data.errorCode ?? response.status,
            status: response.status,
        };
    }
}

/**
 * Navigates the user to the serverErrorForSection page if the user is not already in that page.
 * @param history {History}
 * @param location {Location}
 */
const navigateToServerErrorPage = (history: History, location: Location) => {
    //TODO:
    // if (location.pathname.includes(routes.error.serverErrorForSection)) return;
    // history.push(`${location.pathname}${routes.error.serverErrorForSection}`)
}

/**
 * Navigates the user to the accessDeniedForSection page if the user is not already in that page.
 * @param history {History}
 * @param location {Location}
 */
const navigateToAccessDeniedPage = (history: History, location: Location) => {
    //TODO:
    // if (location.pathname.includes(routes.error.accessDeniedForSection)) return;
    // history.replace(`${location.pathname}${routes.error.accessDeniedForSection}`)
}

/**
 * Caches the current url and then removes the login information from the localstorage. Redirects the user to the
 * base of auth at the end.
 * @param location
 * @param history
 */
const unAuthorizedResponse = (location?: Location | null, history?: History | null) => {
    CacheService.cacheUrl(location)
    CacheService.removeUserInformation();
    //TODO: replace the url to login page.
}

export default apiCallback;
