/**
 * The Static Text used in this application
 */
export default class ErrorMessages {
    static serverErrorTitle: string = 'Error';
    static serverErrorMessage: string = 'Can not communicate with the servers properly, please try again later.';
    static serverSuccessTitle: string = 'Success';
    static serverSuccessMessage: string = 'Your request completed successfully';
}

