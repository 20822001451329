import {Route, Switch} from "react-router";
import routes, {routesLists} from "../../routes";
import NotFound from "./not_found";
import AccessDenied from "./access_denied";
import ServerError from "./server_error";
import React from "react";


const ErrorViews = () => {

    return (
        <>
            <Switch>
                <Route path={[routes.error.notFound, routes.error.base]} exact>
                    <NotFound/>
                </Route>
                <Route
                    path={[routes.error.accessDenied, routes.error.accessDeniedForSection, ...routesLists.allWithAccessDeniedSection]}
                    exact>
                    <AccessDenied/>
                </Route>
                <Route
                    path={[routes.error.serverError, routes.error.serverErrorForSection, ...routesLists.allWithServerErrorSection]}
                    exact>
                    <ServerError/>
                </Route>
            </Switch>
        </>
    );
}

export default ErrorViews;
