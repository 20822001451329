import React, {useState} from "react";
import {UploadStates} from "../../../../core/constants/enums";
import {CircularProgress, Fade} from "@material-ui/core";
import {Autorenew, CheckCircleOutline, ErrorOutline} from "@material-ui/icons";
import classnames from "classnames";
import IconButton from "../../base/button/icon-button";
import {useTranslation} from "react-i18next";

const TextBubbles = ({data, container, sendAgain}) => {
    const [sending, setSending] = useState(false);
    const [hover, setHover] = useState(false);
    const {t} = useTranslation();

    const isFromCurrent = !data.operator;
    const forwardedData = data.forwardedMsgInfo;


    return (
        <div
            id={`${forwardedData?.submitDateTime?.toString() ?? data.submittedDateTime?.toString()}-${forwardedData?.typeID ?? data.msgType.id}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={classnames(`chat-bubble`, {'currentUser': isFromCurrent})}
        >
            <Fade
                in={data.uploadState?.state === UploadStates.error && hover}
                unmountOnExit mountOnEnter>
                <div className={'d-flex flex-column justify-content-center me-1'}>
                    <IconButton
                        className={'ms-2'}
                        disabled={sending}
                        aria-label={'Resend message'}
                        onClick={() => sendAgain(data, setSending)}
                    >
                        <Autorenew className={'icon normal'}/>
                    </IconButton>
                </div>
            </Fade>
            <div
                className={classnames('bubble',
                    {
                        'currentUser': isFromCurrent,
                        'pending': data.uploadState?.state === UploadStates.pending,
                        'error': data.uploadState?.state === UploadStates.error,

                    })}
                style={{maxWidth: (container?.clientWidth ?? 200) / 2}}>
                <p className={classnames(`text-sm text-left text-wrap`, {
                    [isFromCurrent ? "white" : "text-color-1"]: true,
                })}>
                    {forwardedData?.data ?? data.data ?? t('views.warranty-and-return-chat.text-bubble.no-message')}
                </p>
            </div>
            {
                isFromCurrent
                && (
                    <div className={'d-flex flex-column align-items-end justify-content-end ms-1'}>
                        <Fade
                            in={(data.uploadState?.state === UploadStates.error)}
                            unmountOnExit mountOnEnter>
                            <ErrorOutline className={'icon stroke-red sm'}/>
                        </Fade>
                        <Fade in={data.uploadState?.state === UploadStates.done}
                              unmountOnExit mountOnEnter>
                            <CheckCircleOutline className={'icon fill-grey-light sm'}/>
                        </Fade>
                        <Fade
                            in={(data.uploadState?.state === UploadStates.pending)}
                            unmountOnExit mountOnEnter>
                            <CircularProgress color={'secondary'} size={15}/>
                        </Fade>
                    </div>
                )
            }
        </div>
    );
}

export default TextBubbles;
