import React, {Suspense, useEffect} from "react";
import useRouter from "../hooks/use_router";
import CacheService from "../../core/services/cache_service";
import routes, {routesLists,} from "../routes";
import {matchPath, Redirect, Route, Switch} from "react-router";
import Api from "../../core/services/api_service";
import PublicViews from "./public";
import ErrorViews from "./error";
import Loader from "../components/app-specific/loader";

const Landing = () => {
    const {pathname, history, location} = useRouter();

    /**
     * Sets the history object for api interface.
     * Sets the location object for api interface
     * Listens for the changes in the location object and sets the updated location for api interface
     */
    useEffect(() => {
        Api.setHistory(history);
        Api.setLocation(location);
        return history.listen((location) => {
            Api.setLocation(location);
        });
    }, [location])

    /**
     * Listens for the changes in pathname of the url and with each change, determines if there is a
     * need that the user must be redirected to login page.
     */
    useEffect(() => {
        determineIfShouldRedirectToLogin();
    }, [pathname])

    /**
     * Attaches an event listeners for the storage and with each dispatch, determiens if the user should be
     * redirected to the login page.
     */
    useEffect(() => {
        window.addEventListener('storage', determineIfShouldRedirectToLogin);
        return () => window.removeEventListener('storage', determineIfShouldRedirectToLogin);
    }, [])

    /**
     * Depending on the current route:
     * if route is public or auth => do nothing
     * if route is private or auth.logout => check if the user is logged in and if not in logout or error pages,
     * cache thr url and navigate to login page.
     */
    const determineIfShouldRedirectToLogin = () => {
        const isInPublicRoutes = !!matchPath(pathname, {
            path: [
                ...routesLists.public,
                ...routesLists.auth,
                ...routesLists.error
            ].filter(e => e !== routes.auth.logout),
            exact: true
        });
        const isInPrivateRoutes = !!matchPath(pathname, {
            path: [],
            exact: true
        });
        if (isInPublicRoutes || !isInPrivateRoutes) return;
        // if not logged in, save current url and go to login page
        if (!CacheService.isLoggedIn()) {
            CacheService.cacheUrl(location);
            history.replace(routes.auth.base);
        }
    }

    return (
        <>
            <Suspense
                fallback={(
                    <>
                        <div className={'panel-loading-overlay show'}>
                            <div className={'overlay'}/>
                            <div className={'w-100 min-vh-100 d-flex justify-content-center align-items-center'}>
                                <Loader/>
                            </div>
                        </div>
                    </>
                )}
            >
                <Switch>
                    <Route path={routesLists.public} exact>
                        <PublicViews/>
                    </Route>
                    <Route
                        path={[
                            ...routesLists.error,
                            ...routesLists.allWithAccessDeniedSection,
                            ...routesLists.allWithServerErrorSection
                        ]}>
                        <ErrorViews/>
                    </Route>
                    <Redirect to={routes.error.notFound}/>
                </Switch>
            </Suspense>

        </>
    );
}

export default Landing;
