import {useTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";
import {ReactComponent as CHTPLogo} from "../../../../../assets/logos/chtp-logo-description.svg";
import {ReactComponent as NameIcon} from "../../../../../assets/images/chat-screen/user.svg";
import {ReactComponent as PhoneIcon} from "../../../../../assets/images/chat-screen/phone.svg";
import {ReactComponent as EmailIcon} from "../../../../../assets/images/chat-screen/email.svg";
import React from "react";

const ChatHeader = ({data, loading}) => {
    const {t} = useTranslation();

    return (
        <Row>
            <Col xs={12} lg={6}
                 className={'d-flex justify-content-center align-items-center'}>
                <CHTPLogo className={'logo'}/>
            </Col>
            <Col xs={12} lg={6} className={'text-center text-lg-start text-break'}>
                <div className={'d-flex justify-content-center justify-content-lg-start'}>
                    <p className={'chat-title mt-3 mt-lg-0'}>
                        {t('views.warranty-and-return-chat.title')}
                    </p>
                </div>
                <div
                    className={'vendor-info justify-content-center justify-content-lg-start'}>
                    <NameIcon/>
                    <p className={'title'}>
                        {t('views.warranty-and-return-chat.vendor.name')}:
                    </p>
                    {
                        loading
                            ? <Col>
                                <div className={'loading-div chat-header-value'}>
                                    <div/>
                                </div>
                            </Col>
                            : <p className={'value'}>
                                {data?.vendor?.name ?? '--'}
                            </p>
                    }

                </div>
                <div
                    className={'vendor-info justify-content-center justify-content-lg-start'}>
                    <PhoneIcon/>
                    <p className={'title'}>
                        {t('views.warranty-and-return-chat.vendor.phone')}:
                    </p>
                    {
                        loading
                            ? <Col>
                                <div className={'loading-div chat-header-value'}>
                                    <div/>
                                </div>
                            </Col>
                            : <p className={'value'}>
                                {data?.vendor?.phone ?? '--'}
                            </p>
                    }
                </div>
                <div
                    className={'vendor-info justify-content-center justify-content-lg-start'}>
                    <EmailIcon/>
                    <p className={'title'}>
                        {t('views.warranty-and-return-chat.vendor.email')}:
                    </p>
                    {
                        loading
                            ? <Col>
                                <div className={'loading-div chat-header-value'}>
                                    <div/>
                                </div>
                            </Col>
                            : <p className={'value'}>
                                {data?.vendor?.email ?? '--'}
                            </p>
                    }

                </div>
            </Col>
        </Row>
    );
}

export default ChatHeader;
