import {History, Location} from 'history';
import ApiRequestDs from "../models/requests/api_request.ds";
import CrudDs from "../models/response/crud.ds";
import apiCallback from "./api_callback";
import {apiMethods} from "../constants/enums";
import {warrantyAndReturnApis} from "../constants/endpoints/api_endpoints";

/**
 * Interface that handles all the api calls to the server.
 */
export default class Api {
    static history: History;
    static location: Location;

    // ########################################     Helper Methods    ########################################

    /**
     * Updates the history of Api
     * @param history
     */
    static setHistory = (history: History) => {
        if (!history) return;
        Api.history = history;
    }

    /**
     * Updates the location of Api
     * @param location
     */
    static setLocation = (location: Location) => {
        if (!location) return;
        Api.location = location;
    }

    /**
     * Prepares the APi Request by injecting the history and location into the api call back
     * @param {ApiRequestDs | any} request
     * @return {ApiRequestDs}
     */
    static prepareRequest = (request: ApiRequestDs): ApiRequestDs => {
        return {
            ...request,
            history: Api.history,
            location: Api.location,
        }
    }

    // ########################################   PartSupportVendorsMsg  APIS   ########################################

    /**
     * Gets the messages and the request information of a selected warranty and return request.
     * @param vendorToken {string}
     * @return {Promise<CrudDs<unknown>|undefined>}
     */
    static getWarrantyAndReturnInformation = async (vendorToken: string): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: warrantyAndReturnApis.getInformation(vendorToken),
            method: apiMethods.get,
            redirectToError: true,
        }))
    }

    /**
     * Gets the messages and the request information of a selected warranty and return request.
     * @param data {any}
     * @return {Promise<CrudDs<unknown>|undefined>}
     */
    static sendWarrantyAndReturnMessage = async (data: any): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: warrantyAndReturnApis.sendMessage,
            body: data,
            method: apiMethods.post,
        }))
    }


    // ########################################     LOCAL FETCHER     ########################################

    /**
     * Fetches the local json files of the application that are located in the public folder.
     *
     * @param {string[]} fileNames the name of the files to be fetched
     * @return {Promise<{data: any, fileName: string}[]>}
     */
    static fetchLocalJsonFiles = async (fileNames: string[]): Promise<{ data: any, fileName: string }[]> => {
        const localFiles: any = {};
        const promises: Promise<{ data: any, fileName: string }>[] = [];
        fileNames.forEach((fileName) => {
            promises.push(Api._localFileFetcher(`${fileName}.json`));
        });
        return await Promise.all(promises).then((results) => {
            [...results].forEach((result) => {
                // make sure to use camelCase notation for the fileNames after the fetch in case they were snakeCase
                const fileName = result.fileName.replaceAll(new RegExp(/-[a-z]/g), (string) => `${string.charAt(1).toUpperCase()}`)
                localFiles[fileName] = result.data;
            })
            return localFiles;
        });
    }

    /**
     * Fetches a single file from the public folder of the application
     * @param {string} filename
     * @private
     */
    static _localFileFetcher = async (filename: string): Promise<{ data: any | null, fileName: string }> => {
        try {
            const fetchResult = await fetch(`${process.env.PUBLIC_URL}/${filename}`);
            return {data: fetchResult.json(), fileName: filename}
        } catch (e) {
            return {data: null, fileName: filename};
        }
    }
}
