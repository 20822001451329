import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import useWindowViewportWidth from "../../../hooks/use_window/useWindowViewportWidth";
import {createUUId, openInNewTab, readFile} from "../../../../core/services/utils";
import moment from "moment";
import Api from "../../../../core/services/api_service";
import useRouter from "../../../hooks/use_router";
import useIsMounted from "../../../hooks/use_is_mounted";
import {
    ChatMessageOperationTypes,
    ReadingFileAs,
    UploadStates,
    WarrantyAndReturnMessageTypes
} from "../../../../core/constants/enums";
import {chatSectionId} from "../../../../core/constants/ids";
import ChatTableXs from "./table/xs";
import ChatTableLg from "./table/lg";
import ChatHeader from "./header";
import ChatMainSection from "./main-section";
import ChatFooter from "./footer";
import LanguageSettings from "../../../components/app-specific/language-settings";
import routes from "../../../routes";


const WarrantyAndReturnChatView = () => {
    const {params, history} = useRouter();
    const [data, setData] = useState({});
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const windowViewportWidth = useWindowViewportWidth();
    const isMounted = useIsMounted();

    const latestOrderIndex = Math.max(...chats?.map(e => e.orderIndex ?? -1), 0);


    /**
     * Listens to the changes in token of the chat view and with each change, fetches the appropriate chat
     * information for the user.
     */
    useEffect(() => {
        if (!params.token?.length) {
            history.push(routes.error.accessDenied);
            return;
        }
        getWarrantyAndReturnInformation();
    }, [params.token]);


    /**
     * Fetches the request information from the server, and if the result of the api is successful, sets the request
     * info.
     */
    const getWarrantyAndReturnInformation = () => {
        setLoading(true);
        Api.getWarrantyAndReturnInformation(params.token).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setData(response?.data?.partSupportReqInfo ?? {});
                setChats(response?.data?.messagesList
                    ?.map(e => e.msgType?.id === WarrantyAndReturnMessageTypes.image
                        ? ({
                            ...e,
                            _reactKey: createUUId(),
                            uploadState: {state: UploadStates.done},
                        })
                        : ({
                            ...e,
                            _reactKey: createUUId(),
                            uploadState: {state: UploadStates.done}
                        })
                    ));
                if (response?.data?.messagesList?.length) {
                    setTimeout(() => {
                        if (!isMounted()) return;
                        scrollInCaseOfChatChange(response.data.messagesList[response.data.messagesList.length - 1], ChatMessageOperationTypes.add);
                    }, 600)
                }
            } else {
                history.push(routes.error.accessDenied);
            }
            setLoading(false);
        })
    }

    /**
     * Calls api for sending a new Message and then if the response returns the result.
     * @param {{msgTypeId: number, textMsg: string | undefined, content: string, submittedDateTime: Date, _reactKey: string, data: string}} data
     * @param {boolean} resend
     */
    const sendWarrantyAndReturnMessage = async (data, resend = false) => {
        const forApi = {
            token: params.token,
            msgTypeId: data.msgType?.id,
            ...(([
                    WarrantyAndReturnMessageTypes.image,
                    WarrantyAndReturnMessageTypes.pdf,
                    WarrantyAndReturnMessageTypes.video,
                ].includes(data.msgType?.id))
                    ? {
                        content: data.data,
                        fileName: data.fileName,
                    }
                    : {
                        textMsg: data.data,
                    }
            ),
        };
        const response = await Api.sendWarrantyAndReturnMessage(forApi);
        if (!isMounted()) return;
        if (response?.resultFlag) {
            setChats(prevState => prevState.map(e => moment(e.submittedDateTime).isSame(moment(data.submittedDateTime), 'seconds')
                ? {
                    ...e,
                    ...response?.data,
                    _reactKey: resend ? createUUId() : data._reactKey,
                    uploadState: {state: UploadStates.done}
                }
                : e
            ));
        } else {
            setChats(prevState => prevState.map(e => moment(e.submittedDateTime).isSame(moment(data.submittedDateTime), 'seconds')
                ? {
                    ...e,
                    uploadState: {state: UploadStates.error}
                }
                : e
            ));
        }
    }

    /**
     * Scrolls the chat section to the message being operated on.
     *
     * if the type is add message => scrolls to the end of the messages
     * @param message {any}
     * @param type {string}
     */
    const scrollInCaseOfChatChange = (message, type) => {
        if (!message) return;
        const chatSection = document.getElementById(chatSectionId);
        if (!chatSection) return;
        switch (type) {
            case ChatMessageOperationTypes.add:
                chatSection.scrollTo({top: chatSection.scrollHeight});
                break;
            case ChatMessageOperationTypes.remove:
                break;
            default:
                break;
        }
    }

    /**
     * opens the part information in a new tab of users' browser
     */
    const openPartInformationTab = () => {
        openInNewTab(data.partShareLinkAddress);
    }

    /**
     * For each of the items in the data, sends a new message.
     * @param messages {any[]}
     */
    const sendNewMessages = async (messages) => {
        setSending(true);
        for (const message of messages) {
            await _sendNewMessage(message);
        }
        setSending(false);
    }

    /**
     * Sends a new message for the operator to the user by api, and if the result of the api is successful, or
     * erroneous, appends to the list of chats, with their status.
     *
     * if the message is an image, also attaches the temporary url.
     * Also attaches the progress status of the message at the beginning and end for ui reflections.
     * @param data {any}
     * @private
     */
    const _sendNewMessage = async (data) => {
        //TODO: fix here and one other place. Add the preview files as well
        debugger
        data['uploadState'] = {state: UploadStates.pending};
        if (data.msgType?.id === WarrantyAndReturnMessageTypes.image) {
            data['tempUrl'] = await readFile(data.data, ReadingFileAs.dataUrl);
        }
        if ([
            WarrantyAndReturnMessageTypes.image,
            WarrantyAndReturnMessageTypes.pdf,
            WarrantyAndReturnMessageTypes.video,
        ].includes(data.msgType?.id)) {
            data['fileName'] = data.data.name;
            data['data'] = btoa(await readFile(data.data, ReadingFileAs.binaryString));
        }

        setChats(prevState => [...prevState, data]);
        setTimeout(() => {
            if (!isMounted()) return;
            scrollInCaseOfChatChange(data, ChatMessageOperationTypes.add);
        }, 500)
        await sendWarrantyAndReturnMessage(data);
    }


    /**
     * Resends a message that is failed to be sent for the operator to the user by api, and if the result of the api is
     * successful, or erroneous, appends to the list of chats, with their status.
     *
     * Resets the progress status of the message at the beginning and end for ui reflections.
     * @param data {any}
     * @param setLoading {function} the function to set the state to loading
     */
    const resendMessage = async (data, setLoading) => {
        setLoading(true);
        setChats(prevState => prevState.map(e => moment(e.submittedDateTime).isSame(moment(data.submittedDateTime), 'seconds')
            && e.id === data.id
                ? {...data, uploadState: {state: UploadStates.pending}}
                : e
        ));
        setTimeout(() => {
            if (!isMounted()) return;
            scrollInCaseOfChatChange(data, ChatMessageOperationTypes.add);
        }, 0)
        await sendWarrantyAndReturnMessage(data, true);
        setLoading(false);
    }


    /**
     * Creates a chat object for a new message in chat section
     * @param {any} e
     * @param {number} index
     * @param {number} type
     * @return {{_reactKey: string, msgType: {id: number}, data: *, vendor: null, submittedDateTime: string, isForwardedMsg: boolean, orderIndex: number, id: `new-message-${number}`, isActive: boolean, operator: {}, forwardedMsgInfo: null}}
     */
    const createChatObject = (e, type, index) => ({
        id: `new-message-${latestOrderIndex + 1 + index}`,
        data: e,
        submittedDateTime: moment().toDate().toString(),
        orderIndex: latestOrderIndex + 1 + index,
        vendor: {},
        operator: null,
        isActive: true,
        forwardedMsgInfo: null,
        isForwardedMsg: false,
        _reactKey: createUUId(),
        msgType: {
            id: type,
        },
    })

    /**
     * Creates the text object for the new message of chat section
     * @param {any} e
     * @param {number} index
     * @return {{_reactKey: string, data, requestId, orderIndex: *, senderTypeID: number, typeID: number, id: string, submitDateTime: string}}
     */
    const createChatSectionTextObject = (e, index) => ({
        id: `new-text-message-${latestOrderIndex + index + 1}`,
        data: e,
        submittedDateTime: moment().toDate().toString(),
        orderIndex: latestOrderIndex + 1 + index,
        vendor: {},
        operator: null,
        isActive: true,
        forwardedMsgInfo: null,
        isForwardedMsg: false,
        _reactKey: createUUId(),
        msgType: {
            id: WarrantyAndReturnMessageTypes.text,
            name: "Image"
        },
    })

    /**
     * Creates the time object for the chat section
     * @param {string} time
     * @return {{data: (null|string|*), typeID: number, submitDateTime: (null|string|*)}}
     */
    const createChatSectionTimeObject = (time) => ({
        id: time,
        data: time,
        submittedDateTime: time,
        orderIndex: -1,
        vendor: null,
        operator: null,
        isActive: true,
        forwardedMsgInfo: null,
        isForwardedMsg: false,
        msgType: {
            id: WarrantyAndReturnMessageTypes.time,
            name: "Time"
        },
    });

    return (
        <>
            <div className={'chat-layout'}>
                <div className={'inner-layout'}>
                    <LanguageSettings/>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className={'my-2 chat-paper'}>
                                    <ChatHeader data={data} loading={loading}/>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className={'my-2 chat-paper chat-table'}>
                                    {
                                        ['xs', 'sm', 'md'].includes(windowViewportWidth)
                                            ? <ChatTableXs
                                                data={data}
                                                openPartInformationTab={openPartInformationTab}
                                                loading={loading}
                                            />
                                            : <ChatTableLg
                                                data={data}
                                                openPartInformationTab={openPartInformationTab}
                                                loading={loading}
                                            />
                                    }
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className={'mb-2 chat-paper main-section'}>
                                    <ChatMainSection
                                        send={sendNewMessages}
                                        sending={sending}
                                        chats={chats}
                                        resend={resendMessage}
                                        createObject={createChatObject}
                                        createTime={createChatSectionTimeObject}
                                    />
                                    <ChatFooter
                                        sending={sending}
                                        data={data}
                                        send={sendNewMessages}
                                        chats={chats}
                                        createObject={createChatObject}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default WarrantyAndReturnChatView;
