import * as $ from 'jquery';

/**
 * Extends the text-fitter to the JQuery
 * @param $ Jquery
 */
function extendFitText($) {

    $.fn.fitText = function (options) {
        const settings = $.extend({
            'minFontSize': Number.NEGATIVE_INFINITY,
            'maxFontSize': Number.POSITIVE_INFINITY,
            'minLength': Number.NEGATIVE_INFINITY,
            'maxLength': Number.POSITIVE_INFINITY,
            'minOpacity': Number.NEGATIVE_INFINITY,
            'maxOpacity': Number.POSITIVE_INFINITY,
            'minFontWeight': Number.NEGATIVE_INFINITY,
            'maxFontWeight': Number.POSITIVE_INFINITY,
        }, options);

        return this.each(function () {

            // Store the object
            const $this = $(this);

            /**
             * resizes items based on the length of the inner text of the objects and the available range
             */
            const resizer = function () {
                const length = $this.children()[0].innerHTML?.length ?? 0;
                const lengthToFontSizeRatio = length / ((settings.maxLength - settings.minLength) / (parseFloat(settings.maxFontSize) - parseFloat(settings.minFontSize)));
                const fontSize = parseFloat(settings.maxFontSize) - lengthToFontSizeRatio;
                $this.css('font-size', fontSize);

                const midPoint = (parseFloat(settings.maxFontSize) + parseFloat(settings.minFontSize)) / 2;
                $this.css('font-weight', fontSize > midPoint ? settings.maxFontWeight : settings.minFontWeight);
                $this.css('opacity', fontSize > midPoint ? settings.minOpacity : settings.maxOpacity);
            };

            // Call once to set.
            resizer();

            // Call on resize. Opera debounces their resize by default.
            $(window).on('resize.fittext orientationchange.fittext', resizer);

        });

    };

}

/**
 * Adds a onDestroyed event listener for the elements of DOM.
 * @return {*|jQuery}
 */
function extendOnDestroyed($) {

    $.event.special.destroyed = {
        remove: function (o) {
            if (o.handler) {
                o.handler()
            }
        }
    }
}

/**
 * Adds a reverse function to the prototype functions of jQuery
 * @return {*|jQuery}
 */
function extendReverse($) {

    $.fn.reverse = function () {
        return this.pushStack(this.get().reverse(), arguments);
    };
}


/**
 * Sets the extensions of the j-query. This function must be called before the application is run.
 */
const setUpJQueryExtensions = () => {
    extendFitText($);
    extendOnDestroyed($);
    extendReverse($);
}

export default setUpJQueryExtensions;
