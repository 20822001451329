// **************** APPLICATION COLORS ****************

export const backgroundColor = 'rgba(255, 255, 255, 1)';

export const primaryColorDark = 'rgba(169, 58, 0, 1)';
export const primaryColor = 'rgb(246, 93, 49)';
export const primaryColorLight = 'rgba(229, 212, 194, 1)';
export const primaryColorLighter = 'rgba(250, 247, 245, 1)';

export const secondaryColorDark = 'rgba(131, 153, 174, 0.2)';
export const secondaryColor = 'rgba(62, 110, 156, 1)';
export const secondaryColorLight = 'rgba(230, 235, 239, 0.4)';
export const secondaryColorLighter = 'rgba(236, 239, 242, 1)';

export const white = 'white';
export const transparent = 'transparent';

export const green = 'rgba(45, 155, 109, 1)';
export const greenLight = 'rgb(187, 250, 219)';

export const red = '#f44336';
export const redLight = '#ef9a9a';

export const defaultShadowColor = '#4F4F4F';

export const grey = `rgba(180, 174, 173, 1)`;
export const greyLight = `rgba(242, 242, 242, 1)`;

export const primaryTextColor = 'rgba(51, 51, 51, 1)';
export const secondaryTextColor = 'rgba(79, 79, 79, 1)';
export const disabledTextColor = 'rgba(189, 189, 189, 0.5)';
export const hintTextColor = 'rgba(189, 189, 189, 1)';
