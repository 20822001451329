import React, {forwardRef} from "react";
import {IconButton as MuiIconButton} from "@material-ui/core";


const IconButton = forwardRef(({
                                   children,
                                   className = '',
                                   ...props
                               }, ref) => {

    return (
        <>
            <MuiIconButton type={"button"}
                           ref={ref}
                           className={className}
                           classes={{root: 'icon-button'}}
                           {...props}
            >
                {children}
            </MuiIconButton>
        </>
    );
});

export default IconButton;
