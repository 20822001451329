import React, {useEffect} from "react";
import routes from "../../../routes";
import useRouter from "../../../hooks/use_router";
import {matchPath} from "react-router";
import {useTranslation} from "react-i18next";


const AccessDenied = () => {
    const {history, location} = useRouter();
    const {t} = useTranslation();

    /**
     * checks the pathname of the url based on accessDeniedForSection route,
     * no prefix => change the url to access denied.
     */
    useEffect(() => {
        if (!!matchPath(location.pathname, {path: routes.error.accessDeniedForSection, exact: false})) {
            history.replace(routes.error.accessDenied);
        }
    }, [history])


    return (
        <div
            className={`error-page ${location.pathname === routes.error.accessDenied ? "root" : ''} row no-gutters flex-column flex-md-row justify-content-around justify-content-md-around align-items-center`}>
            <svg width="380px" height="500px" viewBox="0 0 837 1045" version="1.1"
                 xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                        d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
                        id="Polygon-1" strokeWidth="6"/>
                    <path
                        d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
                        id="Polygon-2" strokeWidth="6"/>
                    <path
                        d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
                        id="Polygon-3" strokeWidth="6"/>
                    <path
                        d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
                        id="Polygon-4" strokeWidth="6"/>
                    <path
                        d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
                        id="Polygon-5" strokeWidth="6"/>
                </g>
            </svg>
            <div className="message-box">
                <h1>{t('views.errors.accessDenied.title')}</h1>
                <p>{t('views.errors.accessDenied.message')}</p>
                <div className="action-link-wrap">
                    {
                        location.pathname.includes(routes.error.accessDeniedForSection) &&
                        <button onClick={history.goBack} className='animated-button btn'>
                            <span>{t('views.errors.accessDenied.actionButton.back')}</span>
                            <span>{t('views.errors.accessDenied.actionButton.front')}</span>
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}

export default AccessDenied;
