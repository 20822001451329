import React, {useLayoutEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {IconButton} from "../../base/button";
import {Popover} from "@material-ui/core";
import {Col, Container, Row} from "reactstrap";
import Switch from "../../base/switch";
import CacheService from "../../../../core/services/cache_service";
import {appLanguages, ApplicationSettingKeys} from "../../../../core/constants/enums";

const LanguageSettings = () => {
    const {t, i18n} = useTranslation();
    const [popover, setPopover] = useState();
    const [settings, setSettings] = useState(() => CacheService.getSettings());
    const [prevSettings, setPrevSettings] = useState(() => CacheService.getSettings());

    /**
     * Listens to the changes of settings and with each change:
     *
     * * if language has changed, then sets the language of i18n based on the changed language.
     */
    useLayoutEffect(() => {
        const languageKey = ApplicationSettingKeys.language;
        if (settings[languageKey] !== prevSettings[languageKey]) {
            CacheService.setSettings(languageKey, settings[languageKey]);
            i18n.changeLanguage(settings.language).then();
            setPrevSettings(prevState => ({...prevState, [languageKey]: settings.language}));
        }
    }, [settings])


    /**
     * Changes the language of the application between french and english
     * @param {boolean} useLanguage
     * @param {string} language
     */
    const changeLanguage = (language, useLanguage) => {
        if (useLanguage && settings.language !== language) {
            setSettings(prevState => ({...prevState, language: language}))
        }
    }

    return (
        <>
            <div className={'settings'}>
                <IconButton
                    onClick={(e) => setPopover(e.currentTarget)}
                >
                    <div className={'abbr'}>
                        {settings.language}
                    </div>
                </IconButton>
                <Popover
                    id={!!popover ? 'settings' : undefined}
                    elevation={2}
                    classes={{
                        paper: 'settings-popover'
                    }}
                    open={!!popover}
                    onClose={() => setPopover(null)}
                    anchorReference={'anchorEl'}
                    anchorEl={popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Container>
                        <Row>
                            <Col xs={12} className={'mb-4'}>
                                <p className={'title'}>
                                    {t('components.language-settings.title')}
                                </p>
                            </Col>
                            <Col xs={12} className={'d-flex align-items-center justify-content-between mb-2'}>
                                <p className={'entry'}>
                                    {t('components.language-settings.languages.english')}
                                </p>
                                <Switch
                                    value={settings.language === appLanguages.english}
                                    name={appLanguages.english}
                                    onChange={changeLanguage}
                                />
                            </Col>
                            <Col xs={12} className={'d-flex align-items-center justify-content-between mb-2'}>
                                <p className={'entry'}>
                                    {t('components.language-settings.languages.french')}
                                </p>
                                <Switch
                                    value={settings.language === appLanguages.french}
                                    name={appLanguages.french}
                                    onChange={changeLanguage}
                                />
                            </Col>
                            <Col xs={12} className={'d-flex align-items-center justify-content-between mb-2'}>
                                <p className={'entry'}>
                                    {t('components.language-settings.languages.spanish')}
                                </p>
                                <Switch
                                    value={settings.language === appLanguages.spanish}
                                    name={appLanguages.spanish}
                                    onChange={changeLanguage}
                                />
                            </Col>
                            <Col xs={12} className={'d-flex align-items-center justify-content-between mb-2'}>
                                <p className={'entry'}>
                                    {t('components.language-settings.languages.chinese')}
                                </p>
                                <Switch
                                    value={settings.language === appLanguages.chinese}
                                    name={appLanguages.chinese}
                                    onChange={changeLanguage}
                                />
                            </Col>
                        </Row>
                    </Container>

                </Popover>
            </div>

        </>
    )
}

export default LanguageSettings;
