import LocalStorageService from "./local_storage_service";
import moment from "moment";
import {matchPath} from "react-router";
import routes from "../../ui/routes";
import {Location} from 'history';
import {ApplicationSettingKeys} from "../constants/enums";

/**
 * Service that communicates with the local storage of the window
 */
export default class CacheService {
    static keys = LocalStorageService.keys;
    static setLocalStorage = LocalStorageService.set;
    static getLocalStorage = LocalStorageService.get;
    static localStorageRemove = LocalStorageService.remove;
    static localStorageRemoveAll = LocalStorageService.removeAll;

    /**
     * Sets the login information to the local storage of the window
     * @param token {string}
     * @param tokenLifeSpanMinutes {number} the number of minutes until the token expires
     * @param firstName {string} (OPTIONAL) the first name of the user
     * @param lastName {string} (OPTIONAL) the last name of the user
     * @param email {string} (OPTIONAL) the email of the user
     */
    static setLoginInfo(token: string, tokenLifeSpanMinutes: number, firstName?: string, lastName?: string, email?: string) {
        this.setLocalStorage(this.keys.token, token);
        this.setLocalStorage(this.keys.tokenExpiration, new Date().setMinutes(new Date().getMinutes() + tokenLifeSpanMinutes).toString());
        if (firstName) this.setLocalStorage(this.keys.userFirstName, firstName);
        if (lastName) this.setLocalStorage(this.keys.userLastName, lastName);
        if (email) this.setLocalStorage(this.keys.userEmail, email);
    }


    /**
     * Checks to see if the user has a token and their expiry time has not passed
     */
    static isLoggedIn(): boolean {
        return !!this.retrieveTokenAndExpiration();
    }

    /**
     * Retrieves the token and expiry time in minutes form the local storage.
     */
    static retrieveTokenAndExpiration() {
        let token = this.getLocalStorage(this.keys.token);
        let expDateString = this.getLocalStorage(this.keys.tokenExpiration);
        if (isNaN(parseInt(expDateString))) {
            return undefined;
        }
        let expDate = expDateString && moment(parseInt(expDateString));
        let currentDate = moment();
        if (!token || !expDate || currentDate >= expDate) {
            return undefined;
        }
        return {token, tokenExpiration: moment.duration(expDate.diff(currentDate)).asMinutes()};
    };

    /**
     * Logs the user out the system by removing the user from the local storage
     */
    static removeUserInformation() {
        this.localStorageRemove(this.keys.token);
        this.localStorageRemove(this.keys.tokenExpiration);
        this.localStorageRemove(this.keys.userFirstName);
        this.localStorageRemove(this.keys.userLastName);
        this.localStorageRemove(this.keys.userEmail);
    }

    /**
     * Caches the location to the local storage only anf only if the route is not of error, or auth routes
     * @param location {string}
     */
    static cacheUrl(location?: Location | null) {
        if (!location) return;
        if (!location?.pathname.includes(routes.error.base)
            && !matchPath(location.pathname, {path: routes.auth.logout})) {
            const unCacheableRoutes = [routes.error.base, routes.error.accessDeniedForSection, routes.error.serverErrorForSection];
            if (!!matchPath(location.pathname, {path: unCacheableRoutes, exact: false})) return;
            this.setLocalStorage(this.keys.cachedUrl, JSON.stringify(location));
        }
    }

    /**
     * Gets the cached url from the local storage
     */
    static getCachedUrl(): Location | null {
        const cachedUrl = this.getLocalStorage(this.keys.cachedUrl);
        if (cachedUrl) return JSON.parse(cachedUrl);
        return cachedUrl;
    }

    /**
     * Fetches the Settings of the application form the local storage.
     *
     * if settings did not exist, uses the default settings, and if that did not exist, creates and returns the
     * default settings
     */
    static getSettings(): any {
        const settings = this.getLocalStorage(this.keys.settings);
        if (!settings) {
            let defaultSettings = this.getLocalStorage(this.keys.defaultSettings);
            if (!defaultSettings) {
                return CacheService.restoreDefaultSettings();
            }
            return JSON.parse(defaultSettings);
        }
        return JSON.parse(settings);

    }

    /**
     * Sets teh default settings of the application to the local storage and returns the object associated with it.
     */
    static restoreDefaultSettings(): any {
        //TODO: create the default settings here.
        const defaultSettings = {
            [ApplicationSettingKeys.language]: 'en', // default language is english.
        };
        this.setLocalStorage(this.keys.defaultSettings, JSON.stringify(defaultSettings));
        this.setLocalStorage(this.keys.settings, JSON.stringify(defaultSettings));
        return defaultSettings;
    }

    /**
     * Sets one of the keys of the settings to the proposed value.
     * @param {string} key the key of the settings to change
     * @param {any} value the value to change the key to.
     */
    static setSettings(key: string, value: any): void {
        const settings = CacheService.getSettings();
        settings[key] = value;
        LocalStorageService.set(LocalStorageService.keys.settings, JSON.stringify(settings));
    }
}
