import {useCallback, useEffect, useRef} from 'react'

/**
 * Hook for the mounted status of a component.
 * @param debugLabel    the label used for debugging
 * @param log           whether should log
 * @return {function(): boolean} a function that its return value determines if the component is mounted or not.
 */
const useIsMounted = (debugLabel, log = false) => {

    const isMounted = useRef(false)

    useEffect(() => {
        if (log) console.log('mounting', debugLabel)
        isMounted.current = true
        return () => {
            if (log) console.log('un-mounting', debugLabel)
            isMounted.current = false
        }
    }, [])

    return useCallback(() => isMounted.current, []);
}

export default useIsMounted;