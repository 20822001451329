import {useCallback, useEffect, useState} from 'react';

export default function useWindowViewportWidth() {
    const isClient = typeof window === 'object';


    const getViewportWidth = () => {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        if (width <= 576) return 'xs'
        if (width <= 768) return 'sm'
        if (width <= 992) return 'md'
        if (width <= 1200) return 'lg'
        return 'xl'
    }

    const [windowViewportWidth, setWindowViewport] = useState(getViewportWidth());

    const handleResize = useCallback(() => {
        const newViewPortWidth = getViewportWidth();
        if (newViewPortWidth !== windowViewportWidth) setWindowViewport(newViewPortWidth);
    }, [windowViewportWidth])

    useEffect(() => {
        if (!isClient) return;
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleResize]);

    return windowViewportWidth;
}