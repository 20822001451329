import React from "react";
import Moment from "react-moment";

const TimeBubble = ({data}) => {

    return (
        <div
            onDragOver={e => e.preventDefault()}
            onDragEnter={e => e.preventDefault()}
            onDragLeave={e => e.preventDefault()}
            id={`${data.submittedDateTime.toString()}-time`}
            className={'chat-bubble time'}>
            <Moment className={'text-center text-xs text-color-3'} format={'DD MMMM yyyy'}>
                {data.data ?? '--'}
            </Moment>
        </div>
    );
}

export default TimeBubble;
